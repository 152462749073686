import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  update:{updateWidth:false,clientWidth:0}
  },
  getters: {
	  
  },
  mutations: {
	  updateWidth(state,msg){
		state.update.updateWidth=msg;
	  },
    clientWidth(state,msg){
      state.update.clientWidth=msg;
    }
  },
  actions: {
  },
  modules: {
  }
})
